import '@/bootstrap';

import {autoAnimatePlugin} from '@formkit/auto-animate/vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {createSSRApp, h} from 'vue';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import VueNumber from 'vue-number-animation';
import {createPinia} from 'pinia';
import {ZiggyVue} from '@/plugins/ziggy';
import {Ziggy} from '@/ziggy';
import dayjs from '@/plugins/dayjs';
import localeMessages from '@/vue-i18n-locales.generated';
import {createI18n} from 'vue-i18n';
import {flareVue} from "@flareapp/vue";
import flare from '@/plugins/flare';
import VueEasyLightbox from 'vue-easy-lightbox';
import formKitTheme from '@/formkitTheme';
import {createAutoAnimatePlugin} from '@formkit/addons';
import {nl} from '@formkit/i18n';
import {generateClasses} from '@formkit/themes';
import {defaultConfig as FKdc, plugin as FKp} from '@formkit/vue';
import {createGtm} from '@gtm-support/vue-gtm';
import Notifications from 'notiwind';

const pinia = createPinia();

if (import.meta.env.PROD) {
    flare.light();
}

const numberFormats = {
    nl: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        percent: {
            style: 'percent',
            useGrouping: true,
        },
    },
};

createInertiaApp({
    progress: {color: '#4F46E5', delay: 500},
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            numberFormats,
            legacy: false,
            locale: currentLocale,
            fallbackLocale: 'en',
            messages: localeMessages,
        });

        return createSSRApp({render: () => h(App, props)})
            .use(plugin)
            .use(pinia)
            .use(flareVue)
            .use(Notifications)
            .use(ZiggyVue, {...Ziggy, locale: currentLocale})
            .use(dayjs, {locale: currentLocale})
            .use(i18n)
            .use(VueNumber)
            .use(autoAnimatePlugin)
            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    plugins: [createAutoAnimatePlugin({duration: 150})],
                    locales: {nl},
                    locale: 'nl',
                    globalInjection: true,
                })
            )
            .use(
                createGtm({
                    id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
                    enabled: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ENABLED == 'true' && import.meta.env.PROD,
                })
            )
            .use(VueEasyLightbox)
            .mount(el);
    },
});
